import apiInstance from "../api/api";
export type AppData = {
  name: string;
  icon: string;
  id: string;
  external: boolean;
  url: string;
};

export type MeData = {
  is_admin: boolean;
};

export async function getMyApps() {
  const response = await apiInstance.get<AppData[]>("api/myapps");
  return response.data;
}

export async function getMe() {
  const { data } = await apiInstance.get<MeData>("api/me");
  return data;
}
