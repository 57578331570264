import { AxiosError } from "axios";
import { useNotification } from "../context/NotificationContext";

export const useCustomErrorHandling = () => {
  const notification = useNotification();

  const handleCustomError = (error: AxiosError, module: string) => {
    if (error.response) {
      const errorData = error.response.data as unknown;
      if (typeof errorData === "string") {
        notification.notifyError(module, errorData);
      } else {
        console.error("Error data:", errorData);
      }
    }
  };

  return handleCustomError;
};
