import { SearchOutlined } from "@ant-design/icons";
import type { GetRef, TableColumnType, TableColumnsType } from "antd";
import { Button, Form, Input, Space, Table, Typography } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import React, { useEffect, useRef, useState } from "react";
import { TTensurianData } from "../pages/ManageTensurians";

type InputRef = GetRef<typeof Input>;
type DataIndex = keyof TTensurianData;
type IProps = {
  dataSource: TTensurianData[];
  loading: boolean;
};

const TableTensuriansData: React.FC<IProps> = ({ dataSource, loading }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getSearchPropsForMobile = (): TableColumnType<TTensurianData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={"Search by name and address"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, "name")
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, "name")
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="link"
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record["name"]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) ||
        record["address"]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
      );
    },
  });

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<TTensurianData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        //@ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns: TableColumnsType<TTensurianData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
    {
      title: "Tensurians",
      render: (record) => {
        return (
          <React.Fragment>
            <Space direction="vertical" size={3} wrap={true}>
              <Typography.Text strong>{record.name}</Typography.Text>
              {record.address}
              {record.lat + ", " + record.lng}
            </Space>
          </React.Fragment>
        );
      },
      responsive: ["xs"],
      ellipsis: true,
      filterSearch: true,
      ...getSearchPropsForMobile(),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
      width: "20%",
      ...getColumnSearchProps("lat"),
      sorter: (a, b) => a.lat - b.lat,
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
    {
      title: "Longitude",
      dataIndex: "lng",
      key: "lng",
      width: "20%",
      ...getColumnSearchProps("lng"),
      sorter: (a, b) => a.lng - b.lng,
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
  ];

  return (
    <>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{ position: ["topRight"], simple: true, pageSize: 10 }}
        />
      </Form>
    </>
  );
};

export default TableTensuriansData;
