import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Navbar: React.FC = () => {
  const { logout, user, isAdmin } = useAuth();

  const profileItems: MenuProps["items"] = [];

  if (isAdmin === true) {
    profileItems.push({
      label: <Link to={"/admin/users"}> Manage Users and Groups</Link>,
      key: "0",
    });
  }

  profileItems.push({
    label: (
      <a
        role="button"
        onClick={() => {
          logout();
        }}
      >
        Sign Out
      </a>
    ),
    key: "1",
  });

  return (
    <>
      <Link to={"/"} style={{ display: "flex" }}>
        <img
          alt="Tensure"
          src={"/images/logo/Tensure_Logo_White.png"}
          style={{ height: "21px" }}
        />
      </Link>

      <Dropdown menu={{ items: profileItems }} trigger={["click"]}>
        <Avatar
          size={40}
          src={user?.picture}
          icon={<UserOutlined />}
          style={{ cursor: "pointer" }}
        />
      </Dropdown>
    </>
  );
};

export default Navbar;
