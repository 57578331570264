import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import React, { useEffect } from "react";

export const UnauthenticatedLayout = () => {
  const { isUserLoggedIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn]);
  return <Outlet></Outlet>;
};
