import { Outlet } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import { NotificationProvider } from "../context/NotificationContext";

export const AuthProviderLayout = () => {
  return (
    <>
      <AuthProvider>
        <NotificationProvider>
          <Outlet></Outlet>
        </NotificationProvider>
      </AuthProvider>
    </>
  );
};
