import {
  AppstoreOutlined,
  GroupOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Grid, Menu, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import Forbidden from "../../Forbidden";

const { useBreakpoint } = Grid;

const items = [
  {
    key: "users",
    label: <Link to={"/admin/users"}>Users</Link>,
    icon: <UserOutlined />,
  },
  {
    key: "groups",
    label: <Link to={"/admin/groups"}>Groups</Link>,
    icon: <GroupOutlined />,
  },
  {
    key: "applications",
    label: <Link to={"/admin/applications"}>Applications</Link>,
    icon: <AppstoreOutlined />,
  },
];

const AdminLayout: React.FC = () => {
  const { xs } = useBreakpoint();
  const sidebarSpan = xs ? 24 : 6;
  const tableSpan = xs ? 24 : 18;
  const location = useLocation();
  const [current, setCurrent] = useState("");
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrent(location.pathname.split("/")[2]);
  }, [location]);

  switch (isAdmin) {
    case true:
      return (
        <div style={{ marginTop: 25 }} className="container">
          <Row gutter={[32, 32]}>
            <Col span={sidebarSpan} style={{ marginTop: xs ? "10px" : 0 }}>
              <Menu selectedKeys={[current]} mode="inline" items={items} />
            </Col>
            <Col span={tableSpan}>
              <Outlet></Outlet>
            </Col>
          </Row>
        </div>
      );
    case false:
      return <Forbidden />;
    case "loading":
      return <Spin spinning={true} fullscreen />;
  }
};

export default AdminLayout;
