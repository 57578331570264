import React, { createContext, useContext } from "react";
import { notification, ConfigProvider } from "antd";

type NotificationContextType = {
  notifySuccess: (message: string, description: string) => void;
  notifyError: (message: string, description: string) => void;
  notifyWarning: (message: string, description: string) => void;
  notifyInfo: (message: string, description: string) => void;
};

// Create a context
const NotificationContext = createContext<NotificationContextType | null>(null);

// Create a provider
export const NotificationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const triggerNotification = (
    type: "success" | "error" | "warning" | "info",
    message: string,
    description?: string
  ) => {
    notification[type]({
      message,
      description,
    });
  };

  // Success notification
  const notifySuccess = (message: string, description?: string) => {
    triggerNotification("success", message, description);
  };

  // Error notification
  const notifyError = (message: string, description?: string) => {
    triggerNotification("error", message, description);
  };

  // Warning notification
  const notifyWarning = (message: string, description?: string) => {
    triggerNotification("warning", message, description);
  };

  // Info notification
  const notifyInfo = (message: string, description?: string) => {
    triggerNotification("info", message, description);
  };

  const contextValue: NotificationContextType = {
    notifySuccess,
    notifyError,
    notifyWarning,
    notifyInfo,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to raise Notifications
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification is used out of Scope");
  }
  return context;
};
