import { MinusCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Flex,
  Popconfirm,
  Skeleton,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  Application,
  PaginationParams,
  User,
  getApplicationsByGroupId,
  getGroupDetails,
  getUsersByGroupId,
  removeAppsFromGroups,
  removeUsersFromGroups,
} from "../../../api/admin";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { useNotification } from "../../../context/NotificationContext";
import CustomPagination from "../../CustomPagination";
import { AssignApplicationsToGroupModal } from "./AssignApplicationsToGroupModal";
import { AssignUsersToGroupModal } from "./AssignUserToGroupModal";

const { Title, Text } = Typography;

const GroupsDetailsPage: React.FC = () => {
  const { groupId } = useParams();
  const [userQueryParams, setUserQueryParams] = useState<PaginationParams>({
    size: DEFAULT_PAGE_SIZE,
  });
  const [applicationQueryParams, setApplicationQueryParams] =
    useState<PaginationParams>({
      size: DEFAULT_PAGE_SIZE,
    });
  const [userCurrent, setUserCurrent] = useState(1);
  const [applicationCurrent, setApplicationCurrent] = useState(1);
  const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);
  const [showAssignAppsModal, setShowAssignAppsModal] = useState(false);
  const [removeUserEmail, setRemoveUserEmail] = useState<string>();
  const [removeAppId, setRemoveAppId] = useState<string>();
  const notification = useNotification();

  const userColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: any, record: User, index: number) => {
        if (!record.avatar_url && !record.name) {
          return <Tag color="purple">Invited</Tag>;
        } else {
          return (
            <>
              <Flex align="center" gap="small">
                <Avatar
                  size="small"
                  src={record.avatar_url}
                  icon={<UserOutlined />}
                  style={{ cursor: "pointer" }}
                />
                <Text>{value}</Text>
              </Flex>
            </>
          );
        }
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      key: "actions",
      dataIndex: "email",
      render: (value: string) => (
        <Tooltip title="Remove user from group">
          <Popconfirm
            title="Remove the user"
            description="Are you sure you want to remove this user?"
            open={value === removeUserEmail}
            onConfirm={() => removeUser(value)}
            onCancel={() => setRemoveUserEmail(undefined)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: isRemovingUser }}
          >
            <Button
              icon={<MinusCircleOutlined />}
              type="text"
              onClick={() => setRemoveUserEmail(value)}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const applicationColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Application ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Application Type",
      dataIndex: "external",
      key: "external",
      render: (external: boolean) => <>{external ? "External" : "Internal"}</>,
    },
    {
      key: "actions",
      dataIndex: "id",
      render: (value: string) => (
        <Tooltip title="Remove application from group">
          <Popconfirm
            title="Remove the application"
            description="Are you sure you want to remove this application?"
            open={value === removeAppId}
            onConfirm={() => removeApp(value)}
            onCancel={() => setRemoveAppId(undefined)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: isRemovingApp }}
          >
            <Button
              icon={<MinusCircleOutlined />}
              type="text"
              onClick={() => setRemoveAppId(value)}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  // Group
  const {
    data,
    isError,
    isLoading,
    refetch: refetchGroup,
  } = useQuery({
    queryKey: ["groupDetail", groupId],
    queryFn: () =>
      groupId ? getGroupDetails(groupId) : Promise.resolve(undefined),
    enabled: !!groupId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  // Group Users
  const {
    data: userData,
    isError: userIsError,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ["groupUsers", userQueryParams],
    queryFn: () =>
      groupId
        ? getUsersByGroupId(groupId, userQueryParams)
        : Promise.resolve(undefined),
    enabled: !!groupId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  let usersTotal = 0;
  let users: User[] = [];

  if (userIsSuccess && userData && data) {
    users = userData.users;
    usersTotal = data.users.length;
  }

  const userOnPrevClicked = () => {
    const firstItem = users[0];
    const before = firstItem.email;
    setUserQueryParams({
      before: before,
      size: DEFAULT_PAGE_SIZE,
    });
    setUserCurrent(userCurrent - 1);
  };

  const userOnNextClicked = () => {
    const lastItem = users[users.length - 1];
    const after = lastItem.email;
    setUserQueryParams({
      after: after,
      size: DEFAULT_PAGE_SIZE,
    });
    setUserCurrent(userCurrent + 1);
  };

  // Group Applications
  const {
    data: applicationData,
    isError: applicationIsError,
    isLoading: applicationIsLoading,
    isSuccess: applicationIsSuccess,
    refetch: refetchApps,
  } = useQuery({
    queryKey: ["groupApplications", applicationQueryParams],
    queryFn: () =>
      groupId
        ? getApplicationsByGroupId(groupId, applicationQueryParams)
        : Promise.resolve(undefined),
    enabled: !!groupId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  let applicationsTotal = 0;
  let applications: Application[] = [];

  if (applicationIsSuccess && applicationData && data) {
    applications = applicationData.applications;
    applicationsTotal = data.applications.length;
  }

  const { mutate: removeUser, isLoading: isRemovingUser } = useMutation(
    (email: string) =>
      removeUsersFromGroups({
        users: [email],
        groups: [data?.name ?? ""],
      }),
    {
      onSuccess: () => {
        refetchGroup();
        refetchUsers();
        notification.notifySuccess("Success", "Successfully removed user");
      },
      onError: () => {
        notification.notifyError("Error", "Something went wrong");
      },
      onSettled: () => {
        setRemoveUserEmail(undefined);
      },
    }
  );

  const { mutate: removeApp, isLoading: isRemovingApp } = useMutation(
    (appId: string) =>
      removeAppsFromGroups({
        applications: [appId],
        groups: [data?.name ?? ""],
      }),
    {
      onSuccess: () => {
        refetchGroup();
        refetchApps();
        notification.notifySuccess(
          "Success",
          "Successfully removed application"
        );
      },
      onError: () => {
        notification.notifyError("Error", "Something went wrong");
      },
      onSettled: () => {
        setRemoveAppId(undefined);
      },
    }
  );

  const applicationOnPrevClicked = () => {
    const firstItem = applications[0];
    const before = firstItem.id;
    setApplicationQueryParams({
      before: before,
      size: DEFAULT_PAGE_SIZE,
    });
    setApplicationCurrent(applicationCurrent - 1);
  };

  const applicationOnNextClicked = () => {
    const lastItem = applications[applications.length - 1];
    const after = lastItem.id;
    setApplicationQueryParams({
      after: after,
      size: DEFAULT_PAGE_SIZE,
    });
    setApplicationCurrent(applicationCurrent + 1);
  };

  // Render the group details using the fetched data
  return (
    <>
      <div>
        {isLoading && <Skeleton active />}
        {isError && (
          <Alert
            message="Error"
            description="Error while fetching Group details."
            type="error"
            showIcon
          />
        )}
        {data && (
          <>
            <Title level={2} style={{ marginTop: 0 }}>
              {data.name}
            </Title>
            <Flex
              gap="middle"
              align="center"
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              <Flex gap="large" align="center">
                <Statistic title="Users Assigned" value={data.users.length} />
                <Statistic
                  title="Applications Assigned"
                  value={data.applications.length}
                />
              </Flex>
            </Flex>
          </>
        )}
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Space
            align="center"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Typography.Title level={4}>Users</Typography.Title>
            <Button
              type="primary"
              onClick={() => setShowAssignUsersModal(true)}
              disabled={!data}
            >
              Assign users
            </Button>
          </Space>
          <Table
            columns={userColumns}
            dataSource={users}
            pagination={false}
            loading={userIsLoading}
            rowKey={"email"}
          />
          <CustomPagination
            current={userCurrent}
            total={usersTotal}
            loading={userIsLoading}
            pageSize={DEFAULT_PAGE_SIZE}
            onPrevClicked={userOnPrevClicked}
            onNextClicked={userOnNextClicked}
          />
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Space
            align="center"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Typography.Title level={4}>Applications</Typography.Title>
            <Button
              type="primary"
              onClick={() => setShowAssignAppsModal(true)}
              disabled={!data}
            >
              Assign applications
            </Button>
          </Space>
          <Table
            columns={applicationColumns}
            dataSource={applications}
            pagination={false}
            loading={applicationIsLoading}
            rowKey={"id"}
          />
          <CustomPagination
            current={applicationCurrent}
            total={applicationsTotal}
            loading={applicationIsLoading}
            pageSize={DEFAULT_PAGE_SIZE}
            onPrevClicked={applicationOnPrevClicked}
            onNextClicked={applicationOnNextClicked}
          />
        </div>
      </div>
      {data && (
        <>
          <AssignUsersToGroupModal
            isOpen={showAssignUsersModal}
            onClose={() => setShowAssignUsersModal(false)}
            group={data}
            onNewAssignment={() => {
              refetchGroup();
              refetchUsers();
            }}
          />
          <AssignApplicationsToGroupModal
            isOpen={showAssignAppsModal}
            onClose={() => setShowAssignAppsModal(false)}
            group={data}
            onNewAssignment={() => {
              refetchGroup();
              refetchApps();
            }}
          />
        </>
      )}
    </>
  );
};

export default GroupsDetailsPage;
