import { useEffect, useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import firebaseApp from "../../firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { Row, Col, Flex } from "antd";

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");

  const handleGoogleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();
    // add gmail.send scope for passing token to backend for sending notification
    // emails.
    provider.addScope("https://www.googleapis.com/auth/gmail.send");

    try {
      const result: any =  await signInWithPopup(auth, provider);
      const tokenResponse = result["_tokenResponse"]
      const token = await result.user.getIdToken();
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("oauthAccessToken", tokenResponse.oauthAccessToken)
      sessionStorage.setItem("refreshToken", tokenResponse.refreshToken)
      setToken(token);
      navigate("/");
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const pluckRandomImage = () => {
    const imageUrls = [
      "images/pattern/bg_pattern_1.jpg",
      "images/pattern/bg_pattern_2.jpg",
      "images/pattern/bg_pattern_3.jpg",
      "images/pattern/bg_pattern_4.jpg",
    ];

    const randomIndex = Math.floor(Math.random() * imageUrls.length);

    let randomImageUrl;
    randomImageUrl = imageUrls[randomIndex];

    setImageUrl(randomImageUrl);
  };

  useEffect(() => {
    pluckRandomImage();
  }, []);

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 14 }}>
          <div
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              borderRight: "2px solid black",
            }}
          ></div>
        </Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 10 }}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <Flex
            justify={"center"}
            align={"center"}
            vertical
            gap="large"
            style={{ height: "100vh" }}
          >
            <img
              src="/images/logo/Tensure_Logo_Black.png"
              alt="Tensure Logo"
              style={{
                width: "90%",
                maxWidth: "550px",
                height: "auto",
              }}
            />
            <GoogleButton onClick={handleGoogleSignIn} />
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default Login;
