import React, { useEffect, useRef, useState } from "react";

import type { Marker } from "@googlemaps/markerclusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { Popover } from "antd";

type Point = google.maps.LatLngLiteral & { key: string } & { name: string } & {
  address: string;
};
type Props = { points: Point[]; currentFocus: any };

const Markers: React.FC<Props> = ({ points, currentFocus }) => {
  const map = useMap();
  const [markersPin, setMarkersPin] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const [currentMarker, setCurrentMarker] = useState<any>({});

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    if (!map || !currentFocus) return;
    map.panTo(currentFocus);
    setCurrentMarker(currentFocus.key);
  }, [currentFocus]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markersPin));
  }, [markersPin]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markersPin[key]) return;
    if (!marker && !markersPin[key]) return;
    setMarkersPin((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const toggleInfoWindow = (key: string) => {
    closeInfoWindow();
    if (!markersPin[key]) return;
    setTimeout(() => {
      setCurrentMarker(key);
    }, 100);
  };

  const closeInfoWindow = () => {
    setCurrentMarker(null);
  };

  return (
    <>
      {points.map((point) => (
        <AdvancedMarker
          position={point}
          key={point.key}
          ref={(marker) => setMarkerRef(marker, point.key)}
          onClick={() => toggleInfoWindow(point.key)}
          title={point.name}
        >
          <Popover
            open={point.key === currentMarker}
            onOpenChange={(visibility) => {
              if (!visibility) {
                setCurrentMarker(null);
              }
            }}
            content={
              <span>
                <b>{point.address}</b>
                <br />
                Lat: {point.lat} - Lng: {point.lng}
              </span>
            }
            title={point.name}
            trigger="click"
          >
            <img
              src="/tensure-favicon.png"
              alt={point.name}
              style={{
                width: "35px",
                backgroundColor: "orange",
                borderRadius: "25px",
                border: "1.5px solid #23bcac",
              }}
            />
          </Popover>
        </AdvancedMarker>
      ))}
    </>
  );
};

export default Markers;
