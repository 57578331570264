import { FormInstance } from "antd";

type CTX = {
  error: string;
};

interface ErrorData {
  ctx?: CTX;
  input: string;
  loc: string[];
  msg: string;
  type: string;
  url: string;
}

type ErrorDataArray = ErrorData[];

class PydanticError extends Error {
  error: ErrorDataArray;

  constructor(error: ErrorDataArray) {
    super();
    this.error = error;
  }

  // Set form errors using setFields
  setErrorsInFormFields(form: FormInstance) {
    this.error.forEach((errorItem) => {
      let inputName = errorItem.loc[0];
      form.setFields([
        {
          name: inputName,
          errors: [errorItem.msg],
        },
      ]);
    });
  }
}

export default PydanticError;
