import { JwtPayload, jwtDecode } from "jwt-decode";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { emitter } from "../api/api";
import { getMe } from "../api/launcher";

type User = JwtPayload & { name: string; email: string; picture: string };

type AuthContextType = {
  token: string | null;
  setToken: (token: string | null) => void;
  user: User | null;
  isUserLoggedIn: boolean;
  isAdmin: boolean | "loading";
  logout: () => void;
};

// Create a context
const AuthContext = createContext<AuthContextType | null>(null);

// Create a provider
export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    sessionStorage.getItem("token") || null
  );

  // Logout func
  const logout = () => {
    sessionStorage.removeItem("token");
    setToken(null);
  };

  // Decoding and retiving user data from token
  const user = useMemo(() => {
    try {
      const decodedToken = token ? jwtDecode<User>(token) : null;

      if (decodedToken) {
        let userData: User = {
          name: decodedToken.name,
          email: decodedToken.email,
          picture: decodedToken.picture,
        };
        return userData;
      } else {
        return null;
      }
    } catch (error) {
      logout();
      return null;
    }
  }, [token]);

  // User log in status flag
  const isUserLoggedIn = token ? true : false;

  const {
    data: meData,
    isLoading,
    isIdle,
  } = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    enabled: isUserLoggedIn,
    refetchOnWindowFocus: false,
  });

  const isAdmin = isIdle || isLoading ? "loading" : meData?.is_admin ?? false;

  const contextValue: AuthContextType = {
    token,
    setToken,
    user,
    isUserLoggedIn,
    isAdmin,
    logout,
  };

  useEffect(() => {
    const logoutListener = () => {
      logout();
    };
    emitter.on("logout", logoutListener);
    return () => {
      emitter.off("logout", logoutListener);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use Auth elements
export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext is null");
  }

  return authContext;
};
