import { MailOutlined } from "@ant-design/icons";
import { Alert, Flex, Image, Skeleton, Space, Table, Typography } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../../api/admin";

const UserDetailsPage: React.FC = () => {
  const { Text, Title } = Typography;
  const { userId } = useParams<{ userId: string }>();
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ["user", userId],
    queryFn: userId ? () => getUserDetails(userId) : undefined,
    enabled: !!userId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <>
      {isError && (
        <Alert
          description="Error during API call. Please contact admin."
          message="Error"
          type="error"
          showIcon
        />
      )}
      {isLoading && <Skeleton active />}
      {isSuccess && (
        <>
          <Flex gap={40}>
            <Image
              width={150}
              height={150}
              src={data.avatar_url.replace("s96-c", "s200-c")} //https://developers.google.com/people/image-sizing
              preview={false}
              fallback="https://placehold.co/200x200/grey/grey"
              style={{ borderRadius: "50%" }}
              referrerPolicy="no-referrer"
            />

            <Flex vertical gap={10}>
              <Title level={2} style={{ marginTop: 0, marginBottom: 0 }}>
                {data.name}
              </Title>
              <Typography.Link href={"mailto:" + data.email}>
                <Space align="center">
                  <MailOutlined />
                  {data.email}
                </Space>
              </Typography.Link>
            </Flex>
          </Flex>
          <Table
            columns={[
              {
                title: `Groups (${data.groups.length})`,
              },
            ]}
            dataSource={data.groups}
            style={{ paddingTop: "20px" }}
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export default UserDetailsPage;
