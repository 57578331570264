import { Alert, Divider, Flex, Space, Spin, Typography } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { AppData, getMyApps } from "../../api/launcher";
import { useAuth } from "../../context/AuthContext";
import styles from "../../styles/Landingpage.module.css";

const LandingPage: React.FC = () => {
  const { data, isError, isLoading } = useQuery("myapps", getMyApps);
  const { Title, Text } = Typography;
  const { user } = useAuth();
  let history = useNavigate();

  const open_app = (external: AppData["external"], url: AppData["url"]) => {
    if (external) {
      window.open(url, "_blank", "noreferrer");
    } else {
      history(url);
    }
  };

  return (
    <div className="container">
      <Title> Hello, {user?.name}</Title>
      <Divider />
      {isLoading && <Spin />}
      {isError && (
        <Alert
          description="Request Failed. Please try again later."
          message="Request Failed"
          type="error"
          showIcon
        />
      )}
      {data && (
        <Flex wrap="wrap" gap="small">
          {data.map((item, index) => (
            <Space
              align="center"
              direction="vertical"
              key={index}
              className={styles.app_item}
              onClick={() => open_app(item.external, item.url)}
            >
              <img
                alt={item.name}
                src={item.icon}
                width={128}
                height={128}
                style={{ objectFit: "contain" }}
              />

              <Text ellipsis style={{ width: 136 }}>
                {item.name}
              </Text>
            </Space>
          ))}
        </Flex>
      )}
      {data && data.length == 0 && (
        <Alert
          description="You don't have any applications assigned. Please contact admin."
          message="No Applications"
          type="warning"
          showIcon
        />
      )}
    </div>
  );
};

export default LandingPage;
