import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  PaginationParams,
  User,
  deleteUser,
  getUsers,
  inviteUser,
} from "../../../api/admin";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { useAuth } from "../../../context/AuthContext";
import { useNotification } from "../../../context/NotificationContext";
import { removeDomainFromEmail } from "../../../utils";
import PydanticError from "../../../utils/PydanticError";
import { useCustomErrorHandling } from "../../../utils/handleCustomError";
import CustomPagination from "../../CustomPagination";

const Users: React.FC = () => {
  const [queryParams, setQueryParams] = useState<PaginationParams>({
    size: DEFAULT_PAGE_SIZE,
  });
  const notification = useNotification();
  const handleCustomError = useCustomErrorHandling();
  const [current, setCurrent] = useState(1);
  const { user: currentUser } = useAuth();

  const { data, isError, isLoading, isSuccess, refetch, isRefetching } =
    useQuery({
      queryKey: ["users", queryParams],
      queryFn: () => getUsers(queryParams),
      refetchOnWindowFocus: false,
    });
  let total = 0;
  let users: User[] = [];

  if (isSuccess) {
    users = data.users;
    total = data.total;
  }

  type UserFormData = {
    email: string;
  };

  const onPrevClicked = () => {
    const firstItem = users[0];
    const before = firstItem.email;
    setQueryParams({
      before: before,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current - 1);
  };

  const onNextClicked = () => {
    const lastItem = users[users.length - 1];
    const after = lastItem.email;
    setQueryParams({
      after: after,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current + 1);
  };

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [deleteUserEmail, setDeleteUserEmail] = useState<string>();

  const { mutate: inviteUserMutation, isLoading: isMutationLoading } =
    useMutation({
      mutationFn: (email: string) => inviteUser(email),
      onSuccess: (data) => {
        refetch();
        notification.notifySuccess("User", "User invited successfull");
        setOpen(false);
      },
      onError: (error: AxiosError) => {
        if (error instanceof PydanticError) {
          error.setErrorsInFormFields(form);
        } else {
          handleCustomError(error, "User");
        }
      },
    });
  const handleSubmit = async (values: UserFormData) => {
    const email = values.email + "@tensure.io";
    inviteUserMutation(email);
  };

  const { mutate: deleteUserMutation, isLoading: isDeleteUserLoading } =
    useMutation({
      mutationFn: (email: string) => deleteUser(removeDomainFromEmail(email)),
      onSuccess: () => {
        notification.notifySuccess("User", "User Deleted successfull");
        refetch();
      },
      onError: (error: AxiosError) => {
        notification.notifyError("User", "Error during API call");
      },
      onSettled: () => {
        setDeleteUserEmail(undefined);
      },
    });

  const confirm = (email: string) =>
    new Promise((resolve) => {
      resolve(deleteUserMutation(email));
    });

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value: string, record: User, index: number) => (
        <Link to={"/admin/users/" + removeDomainFromEmail(value)}>{value}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string, record: User, index: number) => {
        if (!record.avatar_url && !record.name) {
          return <Tag color="purple">Invited</Tag>;
        }
        return value;
      },
    },
    {
      title: "Action",
      dataIndex: "email",
      render: (value: string) => {
        return (
          <Popconfirm
            title="Delete the user"
            description="Are you sure you want to delete this user?"
            open={value === deleteUserEmail}
            onConfirm={() => confirm(value)}
            onCancel={() => setDeleteUserEmail(undefined)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: isDeleteUserLoading }}
          >
            <Tooltip
              title={
                currentUser?.email != value
                  ? "Delete user"
                  : "Cannot delete yourself"
              }
            >
              <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => setDeleteUserEmail(value)}
                disabled={currentUser?.email == value}
              />
            </Tooltip>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Space
        align="center"
        style={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography.Title style={{ marginTop: 0 }}>Users</Typography.Title>
        <Button
          type="primary"
          onClick={() => setOpen(true)}
          icon={<UserAddOutlined />}
        >
          Invite User
        </Button>
      </Space>

      <Modal
        title="Invite User"
        open={open}
        confirmLoading={isMutationLoading}
        onCancel={() => setOpen(false)}
        onOk={form.submit}
        destroyOnClose
        cancelButtonProps={{ disabled: isMutationLoading }}
      >
        <Form form={form} onFinish={handleSubmit} preserve={false}>
          <Form.Item
            name={["email"]}
            rules={[
              {
                required: true,
                pattern: /^^[a-zA-Z0-9_.+-]+$/,
              },
            ]}
          >
            <Input suffix="@tensure.io" autoComplete="off" />
          </Form.Item>
        </Form>
      </Modal>
      {isError && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message="Error"
            description="Error while fetching API."
            type="error"
            showIcon
          />
        </Space>
      )}
      {!isError && (
        <Table
          columns={columns}
          dataSource={users}
          pagination={false}
          loading={isLoading}
          rowKey={"email"}
        />
      )}
      <CustomPagination
        current={current}
        total={total}
        loading={isLoading}
        pageSize={DEFAULT_PAGE_SIZE}
        onPrevClicked={onPrevClicked}
        onNextClicked={onNextClicked}
      />
    </>
  );
};

export default Users;
