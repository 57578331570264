import { Form, Grid, Modal, Space, Table, TableColumnsType } from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { uploadTensurians } from "../../../api/tensurians";
import { useNotification } from "../../../context/NotificationContext";
import { useCustomErrorHandling } from "../../../utils/handleCustomError";
import { TTensurianData } from "../pages/ManageTensurians";

type IProps = {
  dataSource: TTensurianData[];
  loading: boolean;
  closeModal: () => void;
};

const TensuriansModalList: React.FC<IProps> = ({
  dataSource,
  loading,
  closeModal,
}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleCustomError = useCustomErrorHandling();
  const { useBreakpoint } = Grid;
  const { xs, sm } = useBreakpoint();
  const columns: TableColumnsType<TTensurianData> = [];
  if (xs) {
    columns.push({
      title: "Tensurians",
      render: (value: string, record: TTensurianData, index: number) => (
        <React.Fragment>
          <Space direction="vertical" size={3} wrap={true}>
            {record.name}
            {record.address}
            {record.lat + ", " + record.lng}
          </Space>
        </React.Fragment>
      ),
      responsive: ["xs"],
    });
  } else {
    columns.push(
      {
        title: "Name",
        dataIndex: "name",
        width: "20%",
      },
      {
        title: "Address",
        dataIndex: "address",
        width: "30%",
      },
      {
        title: "Latitude",
        dataIndex: "lat",
        width: "18%",
      },
      {
        title: "Longitude",
        dataIndex: "lng",
        width: "18%",
      }
    );
  }

  const handleOk = () => {
    uploadTensuriansData();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    closeModal();
    setIsModalOpen(false);
  };

  const { mutate: uploadTensuriansMutation, isLoading: isMutationLoading } =
    useMutation({
      mutationFn: () => uploadTensurians(dataSource),
      onSuccess: () => {
        notification.notifySuccess("User", "Tensurians uploaded successfully");
        queryClient.invalidateQueries({ queryKey: ["viewTensurians"] });
      },
      onError: (error: AxiosError) => {
        handleCustomError(error, "Uploadingtensurians");
      },
    });

  const uploadTensuriansData = async () => {
    await uploadTensuriansMutation();
    closeModal();
  };

  return (
    <Modal
      title="Upload Tensurians Data"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
    >
      <Form form={form} component={false}>
        <Table
          dataSource={dataSource}
          loading={loading || isMutationLoading}
          columns={columns}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </Form>
    </Modal>
  );
};

export default TensuriansModalList;
