import { InboxOutlined, UserAddOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  UploadProps,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as XLSX from "xlsx";
import { getTensurians } from "../../../api/tensurians";
import { useNotification } from "../../../context/NotificationContext";
import TensuriansModalList from "../components/ModalTensuriansData";
import TableTensuriansData from "../components/TableTensuriansData";
const { Text } = Typography;

export type TTensurianData = {
  key: number;
  name: string;
  address: string;
  lat: number;
  lng: number;
};

export type TTensurianExportData = Omit<
  {
    key: number;
    name: string;
    address: string;
    lat: number;
    lng: number;
  },
  "key"
>;

const ManageTensurians: React.FC = () => {
  const [modalData, setModalData] = useState<TTensurianData[]>([]); //Temp store from uploaded excel file
  const [isModalOpen, setIsModalOpen] = useState(false);
  const notification = useNotification();

  const {
    data: tensuriansList,
    isError,
    isLoading: isGetMutationLoading,
  } = useQuery({
    queryKey: ["viewTensurians"],
    queryFn: getTensurians,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.map((d: any, key: number) => ({
        key: key + 1,
        ...d,
      })),
  });

  const handleFileUpload = (file: File) => {
    if (file) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        if (e && e.target) {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            blankrows: false,
          });
          /* Update state */
          const excelData: TTensurianData[] = data.map(
            (d: any, index: number) => ({
              key: index + 1,
              name: d[0],
              address: d[1],
              lat: d[2],
              lng: d[3],
            })
          );

          setModalData(excelData);
        }
      };
      if (rABS) reader?.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
  };

  const deaggerFileUploadProps: UploadProps = {
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: (file) => {
      const isXLS =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXLS) {
        message.error(`${file.name} is not a xls or xlsx file`);
        return false;
      }
      handleFileUpload(file);
      return false;
    },
  };

  const exportTensurians = () => {
    const { utils } = XLSX;
    const exportList: TTensurianExportData[] = tensuriansList.map(
      (tl: TTensurianExportData) => ({
        name: tl.name,
        address: tl.address,
        lat: tl.lat,
        lng: tl.lng,
      })
    );
    const ws = utils.json_to_sheet(exportList, { skipHeader: true });
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    XLSX.writeFile(wb, "TensuriansList.xlsx");
  };

  useEffect(() => {
    if (modalData && modalData.length > 0) {
      setIsModalOpen(true);
    }
  }, [modalData]);

  if (isError) {
    notification.notifyError("Managetensurians", "Error during API call");
  }

  return (
    <div className="container">
      <Row>
        <Col sm={6} style={{ padding: "25px" }} xs={24}>
          <Space direction="vertical" size="middle" align="center">
            {tensuriansList && tensuriansList.length > 0 && (
              <>
                <Button
                  type="primary"
                  onClick={exportTensurians}
                  icon={<UserAddOutlined />}
                  style={{ marginTop: "25px" }}
                >
                  Export
                </Button>
                <Text strong>Contains Tensurians list</Text>
              </>
            )}

            <Dragger {...deaggerFileUploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single upload. Only allowing the format .xlsx
              </p>
            </Dragger>
          </Space>
        </Col>
        <Col sm={18} style={{ padding: "25px" }} xs={24}>
          <TableTensuriansData
            dataSource={tensuriansList}
            loading={isGetMutationLoading}
          />
        </Col>
      </Row>

      {isModalOpen && (
        <TensuriansModalList
          dataSource={modalData}
          loading={isGetMutationLoading}
          closeModal={() => {
            setModalData([]);
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ManageTensurians;
