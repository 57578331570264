import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandingPage from "../components/pages/LandingPage";
import Login from "../components/pages/Login";
import AdminLayout from "../components/pages/admin/AdminLayout";
import ApplicationsPage from "../components/pages/admin/ApplicationsPage";
import UsersPage from "../components/pages/admin/UsersPage";

import GroupsPage from "../components/pages/admin/GroupsPage";
import UserDetailsPage from "../components/pages/admin/UserDetailsPage";
import { AuthProviderLayout } from "./AuthProviderLayout";
import { AuthenticatedLayout } from "./AuthenticatedLayout";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";
import ViewTensurians from "../modules/tensurians/pages/ViewTensurians";
import ManageTensurians from "../modules/tensurians/pages/ManageTensurians";
import GroupsDetailPage from "../components/pages/admin/GroupsDetailsPage";

const Routes: React.FC = () => {
  const router = createBrowserRouter([
    {
      element: <AuthProviderLayout />,
      children: [
        {
          element: <AuthenticatedLayout />,
          children: [
            {
              path: "/",
              element: <LandingPage />,
            },
            {
              element: <AdminLayout />,
              children: [
                {
                  path: "/admin/users",
                  element: <UsersPage />,
                },
                {
                  path: "/admin/users/:userId",
                  element: <UserDetailsPage />,
                },
                {
                  path: "/admin/groups",
                  element: <GroupsPage />,
                },
                {
                  path: "/admin/applications",
                  element: <ApplicationsPage />,
                },
                {
                  path: "/admin/groups/:groupId",
                  element: <GroupsDetailPage />,
                },
              ],
            },
            {
              path: "/tensurians",
              element: <ViewTensurians />
            },
            {
              path: "/manage-tensurians",
              element: <ManageTensurians />
            }
          ],
        },
        {
          element: <UnauthenticatedLayout />,
          children: [
            {
              path: "/login",
              element: <Login></Login>,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
