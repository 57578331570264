import { UserOutlined } from "@ant-design/icons";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
  Col,
  Drawer,
  Empty,
  Flex,
  FloatButton,
  Grid,
  Input,
  List,
  Row,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getTensurians } from "../../../api/tensurians";
import { useNotification } from "../../../context/NotificationContext";
import Markers from "../components/Markers";

const { Search } = Input;
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_TOKEN || "";
const MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID || "";

const ViewTensurians: React.FC = () => {
  const [zoom, setZoom] = useState(2.5);
  const [currentFocus, setCurrentFocus] = useState(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const notification = useNotification();

  const focusMarker = (point: any) => {
    setCurrentFocus(point);
    setZoom(18);
  };
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const {
    data: tensuriansAll,
    isError,
    isLoading: isGetTensuriansLoading,
  } = useQuery({
    queryKey: ["viewTensurians"],
    queryFn: getTensurians,
    refetchOnWindowFocus: false,
    select: (res) =>
      res.map((d: any, key: number) => ({
        index: key,
        key: JSON.stringify({ name: d.name, lat: d.lng, lng: d.lat }),
        ...d,
        address: d.address,
        lat: d.lng,
        lng: d.lat,
      })),
  });

  useEffect(() => {
    setData(tensuriansAll);
  }, [tensuriansAll]);

  const searchMap = useCallback((value: string, list: any): void => {
    setLoading(true);
    const cloneData = [...list];
    const res =
      value.trim() === ""
        ? cloneData
        : cloneData.filter((d) => {
            return (
              d.name
                .trim()
                .toLowerCase()
                .includes(value.toLowerCase().trim()) ||
              d.address
                .trim()
                .toLowerCase()
                .includes(value.toLowerCase().trim())
            );
          });
    setData(res);
  }, []);

  useEffect(() => {
    setLoading(false);
    setCurrentFocus(null);
    setZoom(3);
  }, [data]);

  if (isError) {
    notification.notifyError("User", "Error during API call");
  }

  if (xs) {
    return (
      <>
        {(loading || isGetTensuriansLoading) && (
          <Flex gap="small" vertical>
            <Spin tip="Loading" size="large"></Spin>
          </Flex>
        )}
        {!isGetTensuriansLoading && (!data || data.length === 0) && <Empty />}
        {!loading && data && data.length > 0 && (
          <APIProvider apiKey={API_KEY} libraries={["marker"]}>
            <Map
              mapId={MAP_ID}
              defaultCenter={{ lat: 37.0992, lng: -95.7129 }}
              style={{ height: "80vh" }}
              zoom={zoom}
              onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
              gestureHandling={"greedy"}
              disableDefaultUI
            >
              <Markers points={data} currentFocus={currentFocus} />
            </Map>
          </APIProvider>
        )}
        <FloatButton
          onClick={() => setDrawerOpen(true)}
          icon={<UserOutlined />}
        />
        <Drawer
          styles={{ header: { padding: 10 } }}
          title={
            <>
              <Search
                placeholder="Search by Name or City"
                enterButton="Search"
                size="large"
                allowClear={true}
                onSearch={(value) => {
                  searchMap(value, tensuriansAll);
                }}
              />
            </>
          }
          placement={"bottom"}
          onClose={() => setDrawerOpen(false)}
          closeIcon={true}
          open={drawerOpen}
          width={640}
          height={260}
          mask={false}
        >
          <List
            loading={isGetTensuriansLoading}
            itemLayout="horizontal"
            dataSource={data}
            size="small"
            renderItem={(item, _) => (
              <List.Item onClick={() => focusMarker(item)}>
                <List.Item.Meta title={item.name} description={item.address} />
              </List.Item>
            )}
          />
        </Drawer>
      </>
    );
  }

  return (
    <Row style={{ marginLeft: "25px" }}>
      <Col span={6}>
        <Search
          style={{ padding: "25px" }}
          placeholder="Search by Name or City"
          enterButton="Search"
          size="large"
          allowClear={true}
          onSearch={(value) => {
            searchMap(value, tensuriansAll);
          }}
        />
        <List
          loading={isGetTensuriansLoading}
          itemLayout="horizontal"
          dataSource={data}
          style={{ overflow: "auto", height: "75vh" }}
          renderItem={(item, _) => (
            <List.Item onClick={() => focusMarker(item)}>
              <List.Item.Meta
                avatar={
                  <UserOutlined
                    style={{ fontSize: "30px", color: "#23bcac" }}
                  />
                }
                title={item.name}
                description={item.address}
              />
            </List.Item>
          )}
        />
      </Col>
      <Col span={18}>
        {(loading || isGetTensuriansLoading) && (
          <Flex gap="small" vertical>
            <Spin tip="Loading" size="large"></Spin>
          </Flex>
        )}
        {!isGetTensuriansLoading && (!data || data.length === 0) && <Empty />}
        {!loading && data && data.length > 0 && (
          <APIProvider apiKey={API_KEY} libraries={["marker"]}>
            <Map
              mapId={MAP_ID}
              defaultCenter={{ lat: 37.0992, lng: -95.7129 }}
              style={{ height: "85vh" }}
              zoom={zoom}
              onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
              gestureHandling={"greedy"}
              disableDefaultUI
            >
              <Markers points={data} currentFocus={currentFocus} />
            </Map>
          </APIProvider>
        )}
      </Col>
    </Row>
  );
};

export default React.memo(ViewTensurians);
