import NavBar from "./Navbar";
import { Layout } from "antd";
const { Header } = Layout;
const AppLauncherHeader = () => (
  <Header
    style={{
      position: "sticky",
      top: 0,
      zIndex: 1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      justifyContent: "space-between",
    }}
  >
    <NavBar></NavBar>
  </Header>
);

export default AppLauncherHeader;
