import { Flex, Grid, Layout } from "antd";
const { Footer } = Layout;
const { useBreakpoint } = Grid;
const AppLauncherFooter = () => {
  const { xs } = useBreakpoint();
  return (
    <Footer style={{ textAlign: "center" }}>
      {!xs && (
        <Flex align="center" gap={"small"} justify="center">
          <span>
            ©{new Date().getFullYear()} Tensure. All Rights Reserved.
          </span>
          <span>|</span>
          <a href="https://www.tensure.io/legal/privacy-policy" target="_blank">
            Privacy Policy
          </a>{" "}
          <a href="https://www.tensure.io/legal/terms-of-use" target="_blank">
            Terms of Use
          </a>
        </Flex>
      )}
      {xs && (
        <>
          <div>©{new Date().getFullYear()} Tensure. All Rights Reserved.</div>
          <a href="https://www.tensure.io/legal/privacy-policy" target="_blank">
            Privacy Policy
          </a>{" "}
          <a href="https://www.tensure.io/legal/terms-of-use" target="_blank">
            Terms of Use
          </a>
        </>
      )}
    </Footer>
  );
};

export default AppLauncherFooter;
