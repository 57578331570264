import apiInstance from "./api";

export async function getTensurians(filter: any) {
  const { data } = await apiInstance.get("api/tensurians", {});
  return data;
}

//Tensurians Table
export async function uploadTensurians(users: any) {
  const { data } = await apiInstance.post("api/tensurians", { users });
  return data;
}