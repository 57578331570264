import { DeleteOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  ApplicationFormData,
  GetAppsResponse,
  PaginationParams,
  createApplication,
  deleteApp,
  getAppsList,
} from "../../../api/admin";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { useNotification } from "../../../context/NotificationContext";
import PydanticError from "../../../utils/PydanticError";
import { useCustomErrorHandling } from "../../../utils/handleCustomError";
import CustomPagination from "../../CustomPagination";

const Apps: React.FC = () => {
  const notification = useNotification();
  const handleCustomError = useCustomErrorHandling();
  const [queryParams, setQueryParams] = useState<PaginationParams>({
    size: DEFAULT_PAGE_SIZE,
  });
  const [current, setCurrent] = useState(1);
  const [popupDisplay, setPopupDisplay] = useState<string>();

  const { data, isError, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["applications", queryParams],
    queryFn: () => getAppsList(queryParams),
    refetchOnWindowFocus: false,
  });
  let total = 0;
  let apps: GetAppsResponse["applications"] = [];
  const [form] = Form.useForm();

  if (isSuccess) {
    apps = data.applications;
    total = data.total;
  }

  const onPrevClicked = () => {
    const firstItem = apps[0];
    const before = firstItem.id;
    setQueryParams({
      before: before,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current - 1);
  };

  const onNextClicked = () => {
    const lastItem = apps[apps.length - 1];
    const after = lastItem.id;
    setQueryParams({
      after: after,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current + 1);
  };
  const { mutate: deleteApplicationMutation, isLoading: isDeleteCalled } =
    useMutation({
      mutationFn: (id: string) => deleteApp(id),
      onSuccess: () => {
        notification.notifySuccess(
          "Application",
          "Application Deleted successfully"
        );
        refetch();
      },
      onError: (error: AxiosError) => {
        notification.notifyError("Application", "Error during API call");
      },
      onSettled: () => {
        setPopupDisplay(undefined);
      },
    });

  const confirm = (id: string) => {
    deleteApplicationMutation(id);
  };

  const { mutate: createApplicationMutation, isLoading: isClicked } =
    useMutation({
      mutationFn: (formData: ApplicationFormData) =>
        createApplication(formData),
      onSuccess: (data) => {
        refetch();
        closeModal();
        notification.notifySuccess("Application", "Application created");
      },
      onError: (error: AxiosError) => {
        if (error instanceof PydanticError) {
          error.setErrorsInFormFields(form);
        } else {
          handleCustomError(error, "Group");
          closeModal();
        }
      },
    });

  const handleSubmit = async (formData: ApplicationFormData) => {
    createApplicationMutation(formData);
  };

  const validateForm = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((error) => {});
  };

  // Modal handling the form
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Application ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Application Type",
      dataIndex: "external",
      key: "external",
      render: (external: boolean) => <>{external ? "External" : "Internal"}</>,
    },
    {
      title: "",
      dataIndex: "id",
      render: (value: string, record: GetAppsResponse["applications"][0]) => {
        return (
          <Popconfirm
            title="Delete Application"
            description={
              'Are you sure you want to delete "' + record.name + '"'
            }
            open={value === popupDisplay}
            onConfirm={() => confirm(value)}
            onCancel={() => setPopupDisplay(undefined)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: isDeleteCalled || !record.external }}
            cancelButtonProps={{ disabled: isDeleteCalled || !record.external }}
          >
            <Tooltip title={!record.external && "Internal Application"}>
              <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => setPopupDisplay(value)}
                disabled={!record.external}
              />
            </Tooltip>
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <>
      <Space
        align="center"
        style={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography.Title style={{ marginTop: 0 }}>
          Applications
        </Typography.Title>
        <Button type="primary" onClick={showModal}>
          Create Application
        </Button>
      </Space>
      {isError && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message="Error"
            description="Error while fetching API."
            type="error"
            showIcon
          />
        </Space>
      )}
      {!isError && (
        <Table
          columns={columns}
          dataSource={apps}
          pagination={false}
          loading={isLoading}
          rowKey={"id"}
        />
      )}
      <CustomPagination
        current={current}
        total={total}
        loading={isLoading}
        pageSize={DEFAULT_PAGE_SIZE}
        onPrevClicked={onPrevClicked}
        onNextClicked={onNextClicked}
      />

      <Modal
        title="Create Application"
        open={isModalOpen}
        onOk={validateForm}
        onCancel={closeModal}
        okText="Create"
        cancelText="Cancel"
        okButtonProps={{ disabled: isClicked }}
        cancelButtonProps={{ disabled: isClicked }}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          name="form_in_modal"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Icon"
            name="icon"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Apps;
