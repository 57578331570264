import { Flex, Form, Modal, Select, Spin } from "antd";
import { useMutation, useQuery } from "react-query";
import { Group, assignUsersToGroups, getUserSuggestions } from "../../../api/admin";
import { useState } from "react";
import { useNotification } from "../../../context/NotificationContext";

export const AssignUsersToGroupModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  group: Group;
  onNewAssignment: () => void;
}> = ({ isOpen, onClose, group, onNewAssignment }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const { data: userSuggestions, isFetching } = useQuery(["user-suggestions", searchQuery], {
    queryFn: () => getUserSuggestions(searchQuery),
    select: (userEmails) =>
      userEmails
        .filter((x) => group.users.findIndex((email) => email === x) <= 0)
        .map((x) => ({ value: x, label: x })),
    initialData: [],
  });

  const notification = useNotification();

  const {mutate: assignUsers, isLoading: isAssigningUsers} = useMutation(assignUsersToGroups, {
    onSuccess: () => {
      closeModal();
      notification.notifySuccess("Success","Successfully assigned users");
      onNewAssignment();
    },
    onError: () => {
      closeModal();
      notification.notifyError("Error", "Something went wrong");
    }
  });

  const resetModal = () => {
    setSelectedUsers([]);
    setSearchQuery("")
  }

  const closeModal = () => {
    resetModal();
    onClose();
  }

  const handleOk = () => {
    assignUsers({users: selectedUsers, groups:[group.name]})
  }

  return (
    <Modal
      title="Assign users to group"
      open={isOpen}
      onCancel={closeModal}
      onOk={handleOk}
      confirmLoading={isAssigningUsers}
      okButtonProps={{disabled: selectedUsers.length <= 0}}
    >
      <Form>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          onChange={(users) => setSelectedUsers(users)}
          onSearch={(query) => setSearchQuery(query)}
          options={userSuggestions}
          value={selectedUsers}
          notFoundContent={isFetching ? <Flex justify="center" align="center" style={{padding: "8px 4px"}}><Spin size="small" /></Flex> : null}
        />
      </Form>
    </Modal>
  );
};
