import { DeleteOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  PaginationParams,
  createGroup,
  deleteGroup,
  getGroups,
} from "../../../api/admin";
import { DEFAULT_PAGE_SIZE } from "../../../constants";
import { useNotification } from "../../../context/NotificationContext";
import PydanticError from "../../../utils/PydanticError";
import { useCustomErrorHandling } from "../../../utils/handleCustomError";
import CustomPagination from "../../CustomPagination";

type GroupTableData = {
  groups: {
    name: string;
    apps: number;
    users: number;
    is_default_group: boolean;
    is_super_group: boolean;
  }[];
  total: number;
};

type GroupFormData = {
  name: string;
};

const Groups: React.FC = () => {
  const notification = useNotification();
  const handleCustomError = useCustomErrorHandling();
  // Pagination logics
  const [queryParams, setQueryParams] = useState<PaginationParams>({
    size: DEFAULT_PAGE_SIZE,
  });
  const [current, setCurrent] = useState(1);

  const { data, isError, isLoading, isSuccess, refetch, isRefetching } =
    useQuery({
      queryKey: ["groups", queryParams],
      queryFn: () => getGroups(queryParams),
      refetchOnWindowFocus: false,
    });

  let total = 0;
  let groups: GroupTableData["groups"] = [];

  if (isSuccess) {
    groups = data.groups;
    total = data.total;
  }

  const onPrevClicked = () => {
    const firstItem = groups[0];
    const before = firstItem.name;
    setQueryParams({
      before: before,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current - 1);
  };

  const onNextClicked = () => {
    const lastItem = groups[groups.length - 1];
    const after = lastItem.name;
    setQueryParams({
      after: after,
      size: DEFAULT_PAGE_SIZE,
    });
    setCurrent(current + 1);
  };

  // Group post logic
  const [form] = Form.useForm();

  const { mutate: createGroupMutation, isLoading: isCreatingGroup } =
    useMutation({
      mutationFn: (formData: GroupFormData) => createGroup(formData),
      onSuccess: (data) => {
        refetch();
        closeModal();
        notification.notifySuccess("Group", "Group creation successfull");
      },
      onError: (error: AxiosError) => {
        if (error instanceof PydanticError) {
          error.setErrorsInFormFields(form);
        } else {
          handleCustomError(error, "Group");
          closeModal();
        }
      },
    });

  const handleSubmit = async (formData: GroupFormData) => {
    createGroupMutation(formData);
  };

  const validateForm = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((error) => {});
  };

  // Delete Group
  const { mutate: deleteGroupMutation, isLoading: isDeleteGroupLoading } =
    useMutation({
      mutationFn: (group_id: string) => deleteGroup(group_id),
      onSuccess: () => {
        notification.notifySuccess("Group", "Group Deleted successfull");
        refetch();
      },
      onError: (error: AxiosError) => {
        notification.notifyError("Group", "Error during API call");
      },
    });

  const confirm = (group_id: string) =>
    new Promise((resolve) => {
      resolve(deleteGroupMutation(group_id));
    });

  // Modal handling controls
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (group_id: string) => {
        return <Link to={`/admin/groups/${group_id}`}>{group_id}</Link>;
      },
    },
    {
      title: "Apps",
      dataIndex: "apps",
      key: "apps",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "Action",
      dataIndex: "name",
      render: (
        value: string,
        record: {
          name: string;
          apps: number;
          users: number;
          is_default_group: boolean;
          is_super_group: boolean;
        }
      ) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => confirm(value)}
            okButtonProps={{ loading: isDeleteGroupLoading }}
          >
            <Button
              icon={<DeleteOutlined />}
              type="text"
              disabled={record.is_default_group || record.is_super_group}
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Space
        align="center"
        style={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography.Title style={{ marginTop: 0 }}>Groups</Typography.Title>
        <Button type="primary" onClick={showModal}>
          Create Group
        </Button>
      </Space>

      {isError && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Alert
            message="Error"
            description="Error while fetching API."
            type="error"
            showIcon
          />
        </Space>
      )}
      {!isError && (
        <Table
          columns={columns}
          dataSource={groups}
          pagination={false}
          loading={isLoading || isDeleteGroupLoading || isRefetching}
          rowKey={"name"}
        />
      )}
      <CustomPagination
        current={current}
        total={total}
        loading={isLoading || isDeleteGroupLoading || isRefetching}
        pageSize={DEFAULT_PAGE_SIZE}
        onPrevClicked={onPrevClicked}
        onNextClicked={onNextClicked}
      />

      <Modal
        open={isModalOpen}
        title="Create a new group"
        okText="Create"
        cancelText="Cancel"
        onCancel={closeModal}
        onOk={validateForm}
        confirmLoading={isCreatingGroup}
        cancelButtonProps={{ disabled: isCreatingGroup }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="name"
            label="Group Name"
            rules={[
              {
                required: true,
                message: "Please enter the group name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Groups;
