import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import React, { useEffect } from "react";
import { default as Header } from "../components/Header";
import { default as Footer } from "../components/Footer";
import { Layout } from "antd";
const { Content } = Layout;

export const AuthenticatedLayout = () => {
  const { isUserLoggedIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
  }, [isUserLoggedIn]);
  return (
    <>
      <Header />
      <Content>
        <Outlet></Outlet>
      </Content>
      <Footer />
    </>
  );
};
