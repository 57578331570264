import axios, { AxiosError } from "axios";
import EventEmitter from "events";
import PydanticError from "../utils/PydanticError";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 5000,
});

export const emitter = new EventEmitter();

// Adding an interceptor
apiInstance.interceptors.request.use(
  (config) => {
    // Get token from session storage
    const token = sessionStorage.getItem("token");

    // Add the token to the headers
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      let errorData = error.response.data;
      // On Unauthorized access trigger an event
      if (error.response.status === 401) {
        emitter.emit("logout");
      }
      if (
        error.response.status === 400 &&
        typeof error.response.data !== "string" &&
        Array.isArray(errorData) &&
        errorData.length > 0
      ) {
        throw new PydanticError(error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
