import apiInstance from "./api";

export type PaginationParams = {
  size: number;
  after?: string;
  before?: string;
};

// Group Tables
export type Group = {
  applications: string[];
  is_super_group: boolean;
  is_default_group: boolean;
  name: string;
  users: string[];
};

interface GetGroups {
  groups: Group[];
  total: number;
}

export const getGroups = async (queryParams: PaginationParams) => {
  const { data } = await apiInstance.get<GetGroups>("api/admin/groups", {
    params: queryParams,
  });

  const result = data.groups.map((group) => ({
    name: group.name,
    apps: group.applications.length,
    users: group.users.length,
    is_super_group: group.is_super_group,
    is_default_group: group.is_default_group,
  }));

  return {
    groups: result || [],
    total: data.total || 0,
  };
};

// Create Group
type GroupFormData = {
  name: string;
};

export const createGroup = async (formData: GroupFormData) => {
  const response = await apiInstance.post<GroupFormData>(
    "api/admin/group",
    formData
  );
  return response.data;
};

export type User = {
  name: string;
  email: string;
  avatar_url: string;
  groups: [];
};

// Basic Group Details for Group details page
export const getGroupDetails = async (groupId: string) => {
  const { data } = await apiInstance.get<Group>(`api/admin/group/${groupId}`);
  return data;
};

// Get users based on Group ID for Groups details page
export const getUsersByGroupId = async (
  groupId: string,
  queryParams: PaginationParams
) => {
  const { data } = await apiInstance.get<Omit<UserPage, "total">>(
    `api/admin/users/group/${groupId}`,
    {
      params: queryParams,
    }
  );
  return data;
};

// Get applications based on Group ID for Groups details page
export const getApplicationsByGroupId = async (
  groupId: string,
  queryParams: PaginationParams
) => {
  const { data } = await apiInstance.get<Omit<GetAppsResponse, "total">>(
    `api/admin/applications/group/${groupId}`,
    {
      params: queryParams,
    }
  );
  return data;
};

// Delete Group
export async function deleteGroup(group_id: string) {
  const response = await apiInstance.delete(`api/admin/group/${group_id}`);
}

// Users Table
type UserPage = {
  users: User[];
  total: number;
};

export async function getUsers(queryParams: PaginationParams) {
  const { data } = await apiInstance.get<UserPage>("api/admin/users", {
    params: queryParams,
  });
  return data;
}

export async function inviteUser(email: User["email"]) {
  const { data } = await apiInstance.post("api/admin/user", {
    email: email,
    oauthAccessToken: sessionStorage.getItem("oauthAccessToken"),
    refreshToken: sessionStorage.getItem("refreshToken")
  });
  return data;
}

export async function getUserDetails(userId: string) {
  const { data } = await apiInstance.get<User>(`api/admin/user/${userId}`);
  return data;
}

export async function deleteUser(userId: string) {
  const response = await apiInstance.delete(`api/admin/user/${userId}`);
}

// Applications Table
export type Application = {
  external: boolean;
  id: string;
  name: string;
};

export interface GetAppsResponse {
  applications: Application[];
  total: number;
}

export const getAppsList = async (queryParams: PaginationParams) => {
  const { data } = await apiInstance.get<GetAppsResponse>(
    "api/admin/applications",
    {
      params: queryParams,
    }
  );
  return data;
};

// Create Application
export type ApplicationFormData = {
  name: string;
  icon: string;
  url: string;
  id: string;
};

export const createApplication = async (formData: ApplicationFormData) => {
  const response = await apiInstance.post<ApplicationFormData>(
    "api/admin/external_application",
    formData
  );
  return response.data;
};

// Delete Application
export async function deleteApp(app_id: ApplicationFormData["id"]) {
  await apiInstance.delete<void>(`api/admin/external_application/${app_id}`);
}

export const getUserSuggestions = async (query: string) => {
  const response = await apiInstance.get<string[]>("api/admin/user_emails", {
    params: {
      q: query,
    },
  });
  return response.data;
};
export const assignUsersToGroups = async (payload: {
  users: string[];
  groups: string[];
}) => {
  const response = await apiInstance.post(
    "api/admin/assign_users_to_groups",
    payload
  );
  return response.data;
};

export const removeUsersFromGroups = async (payload: {
  users: string[];
  groups: string[];
}) => {
  const response = await apiInstance.post(
    "api/admin/remove_users_from_groups",
    payload
  );
  return response.data;
};

export const getAppSuggestions = async () => {
  const response = await apiInstance.get<{ name: string; id: string }[]>(
    "api/admin/application-names"
  );
  return response.data;
};

export const assignAppsToGroups = async (payload: {
  applications: string[];
  groups: string[];
}) => {
  const response = await apiInstance.post(
    "api/admin/assign_apps_to_groups",
    payload
  );
  return response.data;
};

export const removeAppsFromGroups = async (payload: {
  applications: string[];
  groups: string[];
}) => {
  const response = await apiInstance.post(
    "api/admin/remove_apps_from_groups",
    payload
  );
  return response.data;
};
