import { ThemeConfig } from "antd";

const COLOR_BLUE = "#3EB0Ff";
const COLOR_BLACK = "#000000";
const COLOR_WHITE = "#FFFFFF";
const COLOR_CREAM = "#E7DDD1";
const COLOR_PEACH = "#FFB085";
const COLOR_PURPLE = "#D080E3";
const FONT_GOOPER = "VCGooper-Light";

export const appLauncherTheme: ThemeConfig = {
  token: {
    fontFamily: "ABC Oracle Light Regular",
    fontSize: 16,
  },
  components: {
    Button: {
      borderRadius: 30,
      colorPrimary: COLOR_BLUE,
      colorPrimaryActive: COLOR_PURPLE,
      colorPrimaryHover: COLOR_PURPLE,
      defaultBorderColor: COLOR_BLACK,
    },
    Menu: {
      fontFamily: FONT_GOOPER,
      colorBgTextHover: COLOR_WHITE,
      controlItemBgActive: COLOR_PEACH,
      colorBgContainer: COLOR_CREAM,
      itemSelectedColor: COLOR_BLACK,
    },
    Dropdown: {
      fontFamily: FONT_GOOPER,
    },
    Table: {
      borderColor: COLOR_BLACK,
      headerBg: COLOR_PEACH,
      rowHoverBg: COLOR_WHITE,
      colorBgContainer: COLOR_CREAM,
    },
    Input: {
      colorBorder: COLOR_BLACK,
      colorText: COLOR_BLACK,
    },
    Layout: {
      headerBg: COLOR_BLACK,
      bodyBg: COLOR_CREAM,
      footerBg: COLOR_CREAM,
    },
    Select: {
      colorBorder: COLOR_BLACK,
      colorText: COLOR_BLACK,
    },
  },
};
