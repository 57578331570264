import { Button, Space } from "antd";

export type Props = {
  style?: React.CSSProperties;
  current: number;
  total: number;
  pageSize: number;
  loading: boolean;
  onPrevClicked: () => void;
  onNextClicked: () => void;
};

export const calculateLastPage = (total: number, limit: number) => {
  if (limit == 0) return 0;
  return Math.ceil(total / limit);
};

export default function CustomPagination({
  style,
  current,
  total,
  pageSize,
  loading,
  onPrevClicked,
  onNextClicked,
}: Props) {
  const lastPage = calculateLastPage(total, pageSize);
  return (
    <>
      {total > pageSize && (
        <Space
          style={{
            ...style,
            paddingTop: "10px",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={current === 1 || loading}
            onClick={() => onPrevClicked()}
          >
            Previous
          </Button>
          <Button
            disabled={current === lastPage || loading}
            onClick={() => onNextClicked()}
          >
            Next
          </Button>
        </Space>
      )}
    </>
  );
}
