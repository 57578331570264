import { Flex, Form, Modal, Select, Spin } from "antd";
import { useMutation, useQuery } from "react-query";
import { Group, assignAppsToGroups, getAppSuggestions } from "../../../api/admin";
import { useState } from "react";
import { useNotification } from "../../../context/NotificationContext";

export const AssignApplicationsToGroupModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  group: Group;
  onNewAssignment: () => void;
}> = ({ isOpen, onClose, group, onNewAssignment }) => {
  const [selectedApps, setSelectedApps] = useState<string[]>([]);

  const { data: appSuggestions, isFetching } = useQuery(["app-suggestions"], {
    queryFn: getAppSuggestions,
    select: (appSuggestions) =>
      appSuggestions
        .filter((x) => group.applications.findIndex((app) => app === x.id) <= 0)
        .map((x) => ({ value: x.id, label: x.name })),
    initialData: [],
  });

  const notification = useNotification();

  const {mutate: assignApps, isLoading: isAssigningApps} = useMutation(assignAppsToGroups, {
    onSuccess: () => {
      closeModal();
      notification.notifySuccess("Success","Successfully assigned applications");
      onNewAssignment();
    },
    onError: () => {
      closeModal();
      notification.notifyError("Error", "Something went wrong");
    }
  });

  const resetModal = () => {
    setSelectedApps([]);
  }

  const closeModal = () => {
    resetModal();
    onClose();
  }

  const handleOk = () => {
    assignApps({applications: selectedApps, groups:[group.name]})
  }

  return (
    <Modal
      title="Assign applications to group"
      open={isOpen}
      onCancel={closeModal}
      onOk={handleOk}
      okButtonProps={{disabled: selectedApps.length <= 0}}
      confirmLoading={isAssigningApps}
    >
      <Form>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          onChange={(apps) => setSelectedApps(apps)}
          value={selectedApps}
          options={appSuggestions}
          notFoundContent={isFetching ? <Flex justify="center" align="center" style={{padding: "8px 4px"}}><Spin size="small" /></Flex> : null}
        />
      </Form>
    </Modal>
  );
};
