import "./App.css";
import { ConfigProvider, Layout } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "./routes/Routes";
import { appLauncherTheme } from "./utils/applauncherTheme";
const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider theme={appLauncherTheme}>
      <QueryClientProvider client={queryClient}>
        <Layout style={{ minHeight: "100vh" }}>
          <Routes></Routes>
        </Layout>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
